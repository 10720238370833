import { FC } from "react";
import {
  gridMultiSliderOptionProps,
  ThisOrThatSliderProps,
} from "../../../../../../util/types";
import { Box } from "@mui/system";
import { MultiSliderComp } from "../../../components";
import { Hints } from "../../../../../ui";

const ThisOrThatSlider: FC<ThisOrThatSliderProps> = ({
  actionRequest,
  RowsOption,
  updateMarkValue,
  sliderValue,
  setSliderValue,
  setIsAllSliderValueChange,
}) => {
  const { previous_response } = actionRequest;

  return (
    <>
      {RowsOption?.map((row, parentIndex) => {
        const leftOption = row?.[0];
        const rightOption = row?.[row.length - 1];
        const isIndeZero = parentIndex == 0;
        return (
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: /* c8 ignore next 4 */ isIndeZero ? "end" : "center",
              gap: 3,
              width: "100%",
            }}
            key={leftOption?.value}
          >
            {/* Left hint */}
            <Box sx={{ width: "20%" }}>
              <Hints
                sx={{ textAlign: "right", fontSize: 15 }}
                text={leftOption?.text}
              />
            </Box>
            {/* MultiSlider Component */}
            <Box sx={{ flex: 1 }}>
              <MultiSliderComp
                source="this-or-that"
                interValType={actionRequest?.type}
                interValOption={
                  actionRequest?.row_options[
                    parentIndex
                  ] as unknown as gridMultiSliderOptionProps[]
                }
                updateMarkValue={updateMarkValue}
                sliderValue={sliderValue}
                setSliderValue={setSliderValue}
                parentIndex={parentIndex}
                previous_response={
                  /* c8 ignore next 2 */ previous_response?.[parentIndex]
                }
                setIsAllSliderValueChange={setIsAllSliderValueChange}
              />
            </Box>

            {/* Right hint */}

            <Box sx={{ width: "20%" }}>
              <Hints
                sx={{ textAlign: "left", fontSize: 15 }}
                text={rightOption?.text}
              />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default ThisOrThatSlider;
