export const textFielsStyle = (main_color: string) => ({
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      border: "none",
    },
  },
  "& .MuiInputBase-input": {
    textAlign: "center",
    fontSize: "20px",
    color: main_color,
  },
});

export const inputPropsStyle = {
  "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
    display: "none",
  },
  "& input[type=number]": {
    MozAppearance: "textfield",
  },
};
