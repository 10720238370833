import { diaVideoApicall } from "../../../util/apiCallFunc";

export const handleChange = async (
  value: number,
  setSliderValue: React.Dispatch<React.SetStateAction<number>>,
  body: string,
  offSetSeconds: number
) => {
  setSliderValue(value);

  diaVideoApicall(value, offSetSeconds, body);
};

export const handleKeyDown = async (
  event: React.KeyboardEvent<HTMLDivElement>,
  setSliderValue: React.Dispatch<React.SetStateAction<number>>,
  sliderValue: number,
  offSetSeconds: number,
  body: string
) => {
  const step = 1;

  if (event.key === "ArrowRight") {
    event.preventDefault();
    /* c8 ignore next */
    const value = Math.min(sliderValue + step, 100);
    setSliderValue(value);
    diaVideoApicall(value, offSetSeconds, body);
  } else if (event.key === "ArrowLeft") {
    event.preventDefault();
    /* c8 ignore next */
    const value = Math.max(sliderValue - step, 0);
    setSliderValue(value);
    diaVideoApicall(value, offSetSeconds, body);
  }
};
