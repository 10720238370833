export const test = {}
import { SxProps } from '@mui/system'

export const textStyle: SxProps = {
  marginX: 2,
  fontSize: '17px',
  lineHeight: '1.75',
  textAlign: 'center',
  fontWeight: 600,
  marginBottom: 0,
}
