import React, { useState } from "react";
import "./GridSelectAndMultiSelect.css";
import { GridSelectAndMultiSelectProps } from "./type";
import { actionBtnWrapper } from "../../Rating/styles";
import { Box, Checkbox, Radio } from "@mui/material";
import { Hints, SendUndoButton } from "../../../ui";
import { useThemeContext } from "../../../../globalContext/global-context";
import { ActionResponse } from "chat-ui-react";
import { gridContainer } from "./styles";

const GridSelectComponent: React.FC<GridSelectAndMultiSelectProps> = ({
  actionRequest,
  chatController,
  isCommentModalOpen,
  multiSelect,
}) => {
  const [singleSelections, setSingleSelections] = useState<
    Record<string, number>
  >({});
  const [multiSelections, setMultiSelections] = useState<
    Record<string, number[]>
  >({});

  const { globalObject } = useThemeContext();
  const globalTheme = globalObject?.themeData?.theme;

  const handleSingleSelect = (rowValue: string, optionValue: number) => {
    setSingleSelections((prev) => ({
      ...prev,
      [rowValue]: optionValue,
    }));
  };

  const handleMultiSelect = (rowValue: string, optionValue: number) => {
    setMultiSelections((prev) => {
      const currentSelections = prev[rowValue] || [];
      if (currentSelections.includes(optionValue)) {
        // Remove the option if already selected
        return {
          ...prev,
          [rowValue]: currentSelections.filter(
            (value) => value !== optionValue
          ),
        };
      } else {
        // Add the option to the selections
        return {
          ...prev,
          [rowValue]: [...currentSelections, optionValue],
        };
      }
    });
  };

  const handleSubmit = () => {
    if (multiSelect) {
      // Check if all rows have selections for multi-select
      const allRowsSelected = actionRequest.rows.every(
        (row) => multiSelections[row.value]?.length > 0
      );

      if (allRowsSelected) {
        const options = actionRequest.rows.map((row) => ({
          value: (multiSelections[row.value]).join(","),
        }));

        const res = {
          type: actionRequest?.type,
          value: options.map((item) => item.value).join("|"),
          options,
        };
        chatController.setActionResponse(actionRequest, res as ActionResponse);
      }
    } else {
      // Check if all rows have selections for single-select
      const allRowsSelected = actionRequest.rows.every(
        (row) => singleSelections[row.value] !== undefined
      );

      if (allRowsSelected) {
        const options = actionRequest.rows.map((row) => ({
          value: singleSelections[row.value].toString(),
        }));

        const res = {
          type: actionRequest?.type,
          value: options.map((item) => item.value).toString(),
          options,
        };
        chatController.setActionResponse(actionRequest, res as ActionResponse);
      }
    }
  };

  const requiredItemSelected = () => {
    let isAllSelected;
    if (multiSelect) {
      isAllSelected = !actionRequest.rows.every(
        (row) => multiSelections[row.value]?.length > 0
      );
      return isAllSelected;
    } else {
      isAllSelected =
        Object.keys(singleSelections).length !== actionRequest?.rows.length;
      return isAllSelected;
    }
  };
  return (
    <Box className="grid-parent-container" data-testid="grid-select-component">
      <Box sx={gridContainer(globalTheme)}>
        <Hints
          text={actionRequest.content}
          sx={{
            color: globalTheme?.main_color,
            fontSize: "18px",
            marginY: "20px",
            fontWeight: 500,
            padding: "0 1rem",
          }}
        />
        {actionRequest?.error_message && (
          <Hints
            text={actionRequest?.error_message}
            sx={{ color: globalTheme?.error_color }}
          />
        )}
        <table className="grid-table">
          <thead>
            <tr>
              <th></th>
              {actionRequest.options.map((option) => (
                <th
                  style={{ color: globalTheme?.main_color }}
                  key={option.value}
                >
                  {option.text}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {actionRequest.rows.map((row, rIndex) => (
              <tr key={row.value}>
                <td>
                  {row.image ? (
                    <img alt="" src={row.image} width={50} height={65} />
                  ) : (
                    row.text
                  )}
                </td>
                {actionRequest.options.map((option, oIndex) => (
                  <td key={`${row.value}-${option.value}`}>
                    <Box
                      className={
                        multiSelect ? "checkbox-wrapper" : "radio-wrapper"
                      }
                    >
                      {multiSelect ? (
                        <Checkbox
                          name={row.value}
                          value={option.value}
                          data-testid={`grid-select-${rIndex}-${oIndex}`}
                          checked={
                            multiSelections[row.value]?.includes(
                              option.value
                            ) || false
                          }
                          disabled={option.disabled}
                          onChange={() =>
                            handleMultiSelect(row.value, option.value)
                          }
                          sx={{
                            position: "relative",
                            appearance: "none",
                            width: "20px",
                            height: "20px",
                            margin: 0,
                            cursor: "pointer",
                            backgroundColor: "white",
                            color: globalTheme?.main_color,

                            "&.Mui-checked": {
                              color: globalTheme?.main_color,
                            },
                          }}
                          aria-label={`${row.text} - ${option.text}`}
                        />
                      ) : (
                        <Radio
                          name={row.value}
                          value={option.value}
                          data-testid={`grid-select-${rIndex}-${oIndex}`}
                          checked={singleSelections[row.value] === option.value}
                          disabled={option.disabled}
                          onChange={() =>
                            handleSingleSelect(row.value, option.value)
                          }
                          sx={{
                            position: "relative",
                            appearance: "none",
                            width: "20px",
                            height: "20px",
                            margin: 0,
                            cursor: "pointer",
                            backgroundColor: "white",
                            color: globalTheme?.main_color,

                            "&.Mui-checked": {
                              color: globalTheme?.main_color,
                            },
                          }}
                          aria-label={`${row.text} - ${option.text}`}
                        />
                      )}
                    </Box>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </Box>

      <Box sx={actionBtnWrapper}>
        <SendUndoButton
          disableSendBtn={requiredItemSelected()}
          actionRequest={actionRequest}
          onClick={handleSubmit}
          globalTheme={globalTheme}
          chatController={chatController}
          isCommentModalOpen={isCommentModalOpen}
        />
      </Box>
    </Box>
  );
};

export default GridSelectComponent;
