import React, { useEffect, useRef } from "react";
import { EmbedContentProps } from "../../../util/types";
import { Box } from "@mui/material";
import DOMPurify from "dompurify";
import { useThemeContext } from "../../../globalContext/global-context";
import Hints from "../../ui/Hints";
import { iframeStyle } from "./style";
const EmbedContent: React.FC<EmbedContentProps> = ({
  key,
  actionRequest,
  message,
  isLandScapeMode,
}) => {
  const { globalObject } = useThemeContext();
  const globalTheme = globalObject?.themeData?.theme;

  const embedParser = message?.embed_code
    ? message?.embed_code
    : actionRequest?.embed_code;
  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (containerRef.current && typeof embedParser === "string") {
      // Sanitize content before insertion
      const sanitizedContent = DOMPurify.sanitize(embedParser, {
        FORBID_TAGS: ["script", "style", "form"],
        FORBID_ATTR: ["onerror", "onload", "onclick"],
        ALLOWED_TAGS: ["iframe", "div", "p", "span", "img"],
        ALLOWED_ATTR: [
          "src",
          "width",
          "height",
          "frameborder",
          "allowfullscreen",
          "allow",
        ],
      });
      containerRef.current.innerHTML = sanitizedContent;
    }
  }, [embedParser]);
  return (
    <Box
      data-testid="embed-content-comp"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        flexDirection: "column",
      }}
    >
      {isLandScapeMode && (
        <Hints
          sx={{ color: globalTheme?.main_color, fontSize: 18 }}
          text={actionRequest?.content}
        />
      )}
      <Box ref={containerRef} sx={iframeStyle(isLandScapeMode)} key={key} />
    </Box>
  );
};

export default EmbedContent;
