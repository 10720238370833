import { ThemeObjectInterFace } from "../../../../util/types";

export const gridContainer = (globalTheme: ThemeObjectInterFace) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  flexDirection: "column",
  backgroundColor: "#fff",
  border: `2px solid ${globalTheme?.main_color}`,
  borderRadius: "16px",
  width: "70%",
});
