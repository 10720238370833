import { SxProps } from "@mui/system";

export const chatResponseContainer: SxProps = {
  width: "100%",
  height: "100%",
  padding: {
    sm: 2,
    xs: 1,
  },
  overflow: "auto",
  WebkitOverflowScrolling: "touch",
  flex: 1,
};
