import { Box } from "@mui/system";
import React, { FC } from "react";
import Draggable from "react-draggable";
import CalculateIcon from "@mui/icons-material/Calculate";
import {
  draggableContainer,
  titleBoxConatiner,
  vairableStoreMainContainer,
  varibleStoreId,
} from "./style";
import { VariableStoreFloatingProps } from "../../../../../util/types";
const VariableStoreFloating: FC<VariableStoreFloatingProps> = ({
  storeValues,
  floatinBtnRef,
}) => {
  return (
    <Draggable
      axis="both"
      handle=".handle"
      defaultPosition={{
        x: /* c8 ignore next */ window.innerWidth < 768 ? -90 : -150,
        y: /* c8 ignore next */ window.innerWidth < 768 ? -200 : -300,
      }}
      scale={1}
      cancel="span, svg"
    >
      <Box sx={draggableContainer} className="handle" ref={floatinBtnRef}>
        <Box sx={vairableStoreMainContainer}>
          <Box sx={titleBoxConatiner}>
            <CalculateIcon
              sx={{ color: "white", fontSize: 20 }}
              className="no-cursor"
            />
            <Box
              sx={{
                color: "white",
                fontSize: { md: 14, xs: 12 },
                fontWeight: 900,
              }}
            >
              STORED VARIABLES
            </Box>
          </Box>
          <Box
            sx={{
              maxHeight: { xs: "10rem", md: "20rem" },
              overflowY: "auto",
            }}
          >
            {storeValues?.map((item) => {
              return (
                <Box
                  key={item.id}
                  sx={{
                    display: "flex",
                    gap: 0.5,
                  }}
                >
                  <Box className="no-cursor" sx={varibleStoreId}>
                    {item?.id}:
                  </Box>
                  <Box
                    className="no-cursor"
                    sx={{ color: "white", fontSize: { xs: 12, md: 13 } }}
                  >
                    {item?.value}
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </Draggable>
  );
};

export default VariableStoreFloating;
