import { ChatController, MessageContent } from "chat-ui-react";

export const langUageBaseUpadte = (
  chatCtl: ChatController,
  message: MessageContent,
  index: number
) => {
  if (localStorage.getItem("select") == message) {
    const forLangText = localStorage.getItem("text");
    /* c8 ignore next 7 */
    chatCtl.updateMessage(index, {
      content: forLangText ?? "something went wrong",
      type: "text",
      self: true,
    });
  }
};
export const sliderMessageUpdate = (
  chatCtl: ChatController,
  message: MessageContent,
  index: number
) => {
  if (localStorage.getItem("slider-id") == message) {
    const forLangText = localStorage.getItem("slider-text");
    /* c8 ignore next 6 */
    chatCtl.updateMessage(index, {
      content: forLangText ?? "something went wrong",
      type: "text",
      self: true,
    });
  }
};

// for smoke test
/* c8 ignore next 10*/
export const checkLandscape = (isLandScapeMode: boolean) => {
  const url = window.location.href;
  if (url.includes("12346971")) {
    return url.includes("landscape");
  } else if (isLandScapeMode) {
    return true;
  } else {
    return false;
  }
};

/* c8 ignore next 4 */
export function checkSelectBtn(btn: HTMLElement) {
  expect(btn).toHaveStyle("background-color: rgb(73, 174, 138)");
}
