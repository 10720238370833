import { SxProps } from "@mui/system";
import { ThemeObjectInterFace } from "../../../../util/types";

export const hintWrapper = (isFocused: boolean): SxProps => ({
  display: {
    md: "block",

    xs: /* c8 ignore next 1 */ isFocused ? "none" : "block",
  },
});

export const textActionBtnWrapper: SxProps = {
  width: { xs: "auto", md: "50%" },
  position: { xs: "static", lg: "absolute" },
  bottom: {
    sm: null,
    md: "15px",
  },
  right: {
    sm: null,
    md: "15px",
  },
};

export const inputListConatiner: SxProps = {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  alignItems: "center",
  gap: 1,
  width: "100%",
};

export const inputIndexNumber = (
  globalTheme: ThemeObjectInterFace
): SxProps => ({
  fontSize: 17,
  fontWeight: 700,
  color: globalTheme?.main_color,
});
