export const landScapeOkButtonStyle = {
  display: "flex",
  justifyContent: "center",
  width: "40%",
};

export const potraiteOkButtonStyle = {
  display: "flex",
  justifyContent: "end",
  marginBottom: 2,
  marginRight: 2,
};
