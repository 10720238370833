import React, { FC } from "react";
import GridSelectComponent from "../GridSelectAndMultiSelect";
import { NumericSelectMultiSelectSlider } from "..";
import { QuestionData } from "../GridSelectAndMultiSelect/type";
import { NumericSelectMultiSelectSliderProps } from "../../../../util/types";

const GridSelectParent: FC<NumericSelectMultiSelectSliderProps> = ({
  isLandScapeMode,
  chatController,
  isCommentModalOpen,
  actionRequest,
  setUpdateMarkValue,
  updateMarkValue,
}) => {
  if (actionRequest?.style === "checkbox" || actionRequest?.style === "radio") {
    return (
      <GridSelectComponent
        chatController={chatController}
        isCommentModalOpen={isCommentModalOpen}
        actionRequest={actionRequest as unknown as QuestionData}
        multiSelect={actionRequest?.type === "grid_multi_select"}
      />
    );
  } else {
    return (
      <NumericSelectMultiSelectSlider
        isLandScapeMode={isLandScapeMode}
        chatController={chatController}
        isCommentModalOpen={isCommentModalOpen}
        actionRequest={actionRequest}
        setUpdateMarkValue={setUpdateMarkValue}
        updateMarkValue={updateMarkValue}
      />
    );
  }
};

export default GridSelectParent;
