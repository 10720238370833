import React, { useEffect, useRef, useState } from "react";
import { newRowWithImage, TextData } from "../../../../util/types";
import { Box } from "@mui/system";
import { Hints, QuestionContent, SendUndoButton } from "../../../ui";
import CommonTextInput from "../../../ui/CommonTextInput";
import { hintWrapper, inputIndexNumber, inputListConatiner } from "./style";
import { useThemeContext } from "../../../../globalContext/global-context";
import {
  container,
  inputContainer,
  inputWrapper,
} from "../../../ui/CommonStyles/commonStyle";

const MultiTextInputs: React.FC<TextData> = ({
  actionRequest,
  chatController,
  isLandScapeMode,
  handleImageClick,
  surveyQuestion,
  isCommentModalOpen,
}) => {
  const previousResponse = actionRequest?.previous_response
    ? actionRequest?.previous_response
    : [];
  const [focused, setFocused] = useState(false);
  const { globalObject } = useThemeContext();
  const globalTheme = globalObject?.themeData?.theme;
  const chatCtl = chatController;
  const [value, setValue] = React.useState<string[]>(previousResponse);
  const inputRefs = useRef<(HTMLTextAreaElement | null)[]>([]); // Create the ref array in the parent

  const setResponse = React.useCallback((): void => {
    if (value) {
      const option = value?.map((item) => {
        return { value: item };
      });
      const res = {
        type: "text_list",
        value: value?.map((item) => item).toString(),
        options: option,
      };
      chatCtl.setActionResponse(actionRequest, res);
      setValue([]);
    }
  }, [actionRequest, chatCtl, value]);

  const handleKeyDown = React.useCallback(
    (
      e: React.KeyboardEvent<HTMLTextAreaElement | HTMLInputElement>,
      index?: number
    ): void => {
      /* c8 ignore next 12 */
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        if (value?.length == actionRequest?.rows?.length) {
          setResponse();
        } else if (index !== undefined) {
          const nextInput = inputRefs.current[index + 1];
          if (nextInput) {
            nextInput?.focus(); // Focus the next input field
          }
        }
      }
    },
    [setResponse, value.length, actionRequest?.rows?.length]
  );

  useEffect(() => {
    /* c8 ignore next 4 */
    if (actionRequest?.auto_response == "ok") {
      const res = { type: "auto_response", value: "autoresponse" };
      chatController?.setActionResponse(actionRequest, res);
    }
  }, [actionRequest?.auto_response]);
  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    index?: number
  ) => {
    const { value } = e.target;
    setValue((prev) => {
      const newValue = [...prev];
      if (index !== undefined) {
        newValue[index] = value;
      }
      return newValue;
    });
  };

  const disableSendBtnFun = () => {
    const updateState = value?.filter((item) => item !== "");
    if (actionRequest?.min_options) {
      return actionRequest?.min_options > updateState?.length;
    } else {
      return updateState.length !== actionRequest?.rows?.length;
    }
  };
  return (
    <Box
      data-testid="multi-text-input"
      sx={/* c8 ignore next 2 */ focused ? parent : {}}
    >
      <Box sx={container(isLandScapeMode, focused)}>
        {isLandScapeMode && surveyQuestion ? (
          <QuestionContent
            surveyQuestion={surveyQuestion}
            handleImageClick={handleImageClick}
          />
        ) : null}
        <Box sx={inputContainer(isLandScapeMode, focused)}>
          {isLandScapeMode ? (
            <Box sx={hintWrapper(focused)}>
              <Hints text="Enter your response..." />
            </Box>
          ) : null}
          <Box sx={{ width: "100%", marginLeft: 2 }}>
            {actionRequest?.rows?.map((row: newRowWithImage, index: number) => {
              return (
                <Box
                  key={`multi-input-${(index + 1).toString()}`}
                  sx={inputListConatiner}
                >
                  <Box sx={inputIndexNumber(globalTheme)}>{row?.value}</Box>
                  <Box sx={inputWrapper(isLandScapeMode, globalTheme)}>
                    <CommonTextInput
                      isType={actionRequest?.type}
                      isLandScapeMode={isLandScapeMode}
                      handleKeyDown={handleKeyDown}
                      value={value[index]}
                      handleChange={handleChange}
                      index={index}
                      inputRefs={inputRefs} // Pass the ref array to the child
                      setFocused={setFocused}
                      source={"multi-text-input-field"}
                    />
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      {isLandScapeMode && (
        <SendUndoButton
          isCommentModalOpen={isCommentModalOpen}
          onClick={setResponse}
          chatController={chatController}
          actionRequest={actionRequest}
          globalTheme={globalTheme}
          disableSendBtn={disableSendBtnFun()}
          stopBack={focused}
        />
      )}
    </Box>
  );
};

export default MultiTextInputs;
