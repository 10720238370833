import { SxProps } from "@mui/system";
import { ThemeObjectInterFace } from "../../../../../util/types";

export const gridValueContent = (
  globalTheme: ThemeObjectInterFace,
  isSelected: boolean,
  disabled: boolean | undefined,
  height: number,
  isGrid_numeric: boolean
): SxProps => {
  return {
    height: /* c8 ignore next 2 */ height > 0 ? height : "inherit",
    minHeight: { md: 100, xs: 40 },
    flex: "1 1 auto",
    ...(!isGrid_numeric && {
      padding: { md: "10px 20px", xs: "5px 4px" },
    }),

    borderRight: `1px solid ${globalTheme.main_color}`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "white",
    color: globalTheme?.main_color,
    cursor: "pointer",
    textAlign: "center",
    "&:last-child": {
      borderRight: 0,
    },
    ...(disabled && {
      backgroundColor: "#ddd",
      color: "gray",
    }),
    ...(isSelected && {
      backgroundColor: globalTheme?.main_color,
      color: "white",
    }),
  };
};

export const gridButtonText = (isSelected: boolean): SxProps => ({
  fontSize: { md: "20px", xs: "12px" },
  fontWeight: "400",
  marginBottom: "0",
  ...(isSelected && {
    color: "#fff",
  }),
});
