import { SxProps } from '@mui/system'
import { CustomMessageContent, ThemeObjectInterFace } from '../../../util/types'

export const textContent = (
  globalTheme: ThemeObjectInterFace,
  msg: CustomMessageContent,
): SxProps => {
  let color: string | null = null
  if (msg?.error_message) {
    color = globalTheme?.error_color
  } else if (msg?.self) {
    color = globalTheme?.secondary_color
  }

  return {
    color,
    whiteSpace: 'pre-line',
    lineHeight: 1.3,
    '& *:not(:last-child)': {
      marginBottom: '5px',
    },
  }
}
