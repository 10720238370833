import { SxProps } from "@mui/system";
import { ThemeObjectInterFace } from "../../../../../util/types";

export const cardContainer = (
  isLandScapeMode: boolean,
  globalTheme: ThemeObjectInterFace
): SxProps => ({
  padding: isLandScapeMode ? "" : "8px 16px",
  margin: isLandScapeMode ? "" : "16px 16px 8px",
  borderWidth: isLandScapeMode ? 0 : 2,
  boxShadow: isLandScapeMode ? "" : 3,
  backgroundColor: isLandScapeMode ? "" : "white",
  borderRadius: 10,
  border: isLandScapeMode ? "" : 2,
  borderColor: isLandScapeMode ? "" : globalTheme?.main_color,
});

export const contentContainer = (
  isLandScapeMode: boolean,
  globalTheme: ThemeObjectInterFace,
  isImagePresent: boolean
): SxProps => ({
  /* c8 ignore next 4 */
  width: isLandScapeMode && !isImagePresent ? "50%" : "100%",
  padding: isLandScapeMode ? "0 15px" : null,
  margin: isImagePresent ? null : "0 auto",
  display: "flex",
  alignItems: "center",
  border: isLandScapeMode ? 2 : "",
  borderColor: isLandScapeMode ? globalTheme?.main_color : "",
  backgroundColor: "white",
  "& > *": {
    flexBasis: isLandScapeMode && isImagePresent ? "50%" : "100%",
  },
  ...(isLandScapeMode && {
    height: !isImagePresent ? "30vh" : "60vh",
  }),
});

export const imageContainer: SxProps = {
  width: "auto",
  margin: "8px auto",
};

export const textContainer = (
  isLandScapeMode: boolean,
  isImage: boolean
): SxProps => ({
  display: "flex",
  width: isLandScapeMode && isImage ? "40%" : "100%",
  justifyContent: "center",
});

export const textContent = (
  isLandScapeMode: boolean,
  globalTheme: ThemeObjectInterFace,
  isErrorMessage?: string
): SxProps => ({
  width: {
    sm: isLandScapeMode ? "100%" : "40%",
    xs: "60%",
  },
  padding: 3,
  color: isErrorMessage ? globalTheme?.error_color : globalTheme?.main_color,
  lineHeight: 1.3,
  textAlign: "center",
  fontWeight: isLandScapeMode ? 500 : 300,
  fontSize: "1rem",
  marginBottom: isLandScapeMode ? null : 2,
  border: isLandScapeMode ? "" : 2,
  borderColor: isLandScapeMode ? "" : globalTheme?.main_color,
  wordBreak: "break-all",
});

export const ratingContainer = (isLandScapeMode: boolean): SxProps => ({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  gap: 1,
  marginTop: isLandScapeMode ? 1.5 : 0,
});

export const ratingLabels = (
  isLandScapeMode: boolean,
  globalTheme: ThemeObjectInterFace,
  isSelected: boolean
): SxProps => ({
  border: 2,
  borderRadius: 2,
  padding: 1,
  paddingX: isLandScapeMode ? 4 : null,
  paddingY: isLandScapeMode ? 0.5 : null,
  fontWeight: 500,
  width: "100%",
  textAlign: "center",
  borderColor: globalTheme?.main_color,
  backgroundColor: isSelected ? globalTheme?.main_color : "white",
  color: isSelected ? "white" : globalTheme?.main_color,
  cursor: "pointer",
  fontSize: 16,
});
