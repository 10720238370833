export const iframeStyle = (isLandScapeMode: boolean | undefined) => ({
  paddingX: 4,
  width: "100%",
  height: "auto",
  maxWidth: "100%",
  position: "relative",
  "& iframe": {
    width: "100%",
    height: "100%",
    minHeight: { md: isLandScapeMode ? "700px" : "300px", xs: "250px" },
    border: "none",
  },
  "& img": {
    maxWidth: "100%",
    height: "auto",
  },
  overflow: "hidden",
});
