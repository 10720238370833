import { Slider } from '@mui/material'
import { Box } from '@mui/system'
import React, { FC, SyntheticEvent } from 'react'
import { sliderStyle } from '../../../Rating/components/SliderRating/styles'
import { MultiSliderRatingProps } from '../../../../../util/types'
import { useThemeContext } from '../../../../../globalContext/global-context'
import { interval } from '../GridCommonFunctions'

const MultiSliderComp: FC<MultiSliderRatingProps> = ({
  source,
  interValType,
  interValOption,
  updateMarkValue,
  sliderValue,
  setSliderValue,
  parentIndex,
  previous_response,
  setIsAllSliderValueChange,
}): React.ReactElement => {
  const { globalObject } = useThemeContext()
  const globalTheme = globalObject?.themeData?.theme

  const handleCommitChange = (
    event: SyntheticEvent | Event,
    value: number | number[],
  ) => {
    if (typeof value === 'number') {
      setSliderValue((prevValues) => ({
        ...prevValues,
        [parentIndex]: {
          sliderRatingValue: value,
        },
      }))
      if (setIsAllSliderValueChange) {
        setIsAllSliderValueChange((prevValues) => {
          const updatedValues = [...prevValues]
          updatedValues[parentIndex] = {
            sliderRatingValue: value,
          }
          return updatedValues
        })
      }
    }
  }

  const isSlider = sliderValue[parentIndex]?.sliderRatingValue === undefined
  const interValObject = { options: interValOption, type: interValType }
  return (
    <Box
      sx={{
        width: '100%',
      }}
    >
      <Slider
        data-testid={`slider-${parentIndex}`}
        onChangeCommitted={handleCommitChange}
        aria-label="Custom marks"
        defaultValue={
          previous_response
            ? (+previous_response - 1) * interval(interValObject)
            : undefined
        }
        step={interval(interValObject)}
        value={sliderValue[parentIndex]?.sliderRatingValue}
        marks={updateMarkValue}
        sx={sliderStyle(globalTheme, isSlider, source)}
      />
    </Box>
  )
}

export default MultiSliderComp
