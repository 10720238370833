import { Box } from "@mui/material";
import React, { FC } from "react";
import ActionButton from "../ActionButton";
import DefaultInput from "../DefaultInput";
import { useThemeContext } from "../../../globalContext/global-context";
import {
  CustomMessageContent,
  EmbedContentSendButtonProps,
} from "../../../util/types";
import { landScapeOkButtonStyle, potraiteOkButtonStyle } from "./style";

const EmbedContentSendButton: FC<EmbedContentSendButtonProps> = ({
  isLandScapeMode,
  actReq,
  chatCtl,
}) => {
  const { globalObject } = useThemeContext();
  const globalTheme = globalObject?.themeData?.theme;
  /* c8 ignore next 1 */
  const handleSubmit = () => {
    /* c8 ignore next 11 */
    const res = {
      type: "embed",
      value: "Video viewed",
    };
    chatCtl?.setActionResponse(actReq, res);
    chatCtl.addMessage({
      type: "text",
      self: true,
      content: "OK",
    } as unknown as CustomMessageContent);
  };

  const [buttonEnabled, setButtonEnabled] = React.useState(true);

  React.useEffect(() => {
    const number_seconds = actReq?.number_seconds * 1000;
    const timer = setTimeout(() => {
      /* c8 ignore next 2 */
      setButtonEnabled(false);
    }, number_seconds);

    return () => clearTimeout(timer);
  }, [actReq?.number_seconds]);
  return (
    <>
      {isLandScapeMode ? (
        <Box sx={landScapeOkButtonStyle}>
          <ActionButton
            onClick={handleSubmit}
            testId="landscape-send-button"
            currentVal={{ text: "OK" }}
            disabled={buttonEnabled}
          />
        </Box>
      ) : (
        <Box sx={{ width: "100%" }}>
          <Box sx={potraiteOkButtonStyle}>
            <ActionButton
              onClick={handleSubmit}
              testId="potraite-send-button"
              currentVal={{ text: "OK" }}
              disabled={buttonEnabled}
            />
          </Box>

          <DefaultInput
            chatController={chatCtl}
            actionRequest={actReq}
            placeholder={globalTheme?.hints?.multiselect}
            /* c8 ignore next 2 */
            onClick={() => {}}
          />
        </Box>
      )}
    </>
  );
};

export default EmbedContentSendButton;
