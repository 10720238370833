import { Theme } from "@mui/material";
import { SxProps } from "@mui/system";
import { CSSProperties } from "react";
import { ThemeObjectInterFace } from "../../../util/types";
import { commonWrapper } from "../styles";

export const videoContainer = (
  isSelf: boolean,
  isLandScapeMode: boolean,
  dialOpen: boolean
): SxProps =>
  /* c8 ignore next 13 */
  !dialOpen
    ? {
        paddingLeft: !isLandScapeMode && isSelf ? "20%" : "20px",
        my: isLandScapeMode ? null : 1,
        gap: 2,
        display: isLandScapeMode ? "flex" : null,
        justifyContent: "space-between",
        alignItems: "center",
        "& > *": {
          flexBasis: "calc(50% - 20px)",
        },
      }
    : /* c8 ignore next 2 */ {};

export const videoEndcontainer: SxProps = {
  height: 275,
  width: "100%",
  backgroundColor: "black",
  color: "white",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  borderRadius: 3,
  fontSize: {
    sm: 25,
    xs: 20,
  },
  paddingRight: 3,
  paddingLeft: 3,
  textAlign: "center",
};

export const videoIframe = (isLandScapeMode: boolean): CSSProperties => ({
  height: isLandScapeMode ? "80vh" : 275,
  width: "100%",
  borderRadius: 10,
  borderWidth: 0,
});

export const wrapperStyle = (
  dialOpen: boolean,
  globalTheme: ThemeObjectInterFace,
  slef: boolean,
  timerImageOPen: boolean | undefined,
  isLandScapeMode: boolean | undefined,
  theme: Theme
) =>
  !dialOpen && {
    ...commonWrapper(globalTheme, slef, !!timerImageOPen, isLandScapeMode),
    ...(!isLandScapeMode &&
      !slef && {
        "&::after": {
          borderColor:
            /* c8 ignore next 4 */
            theme.palette.mode === "light"
              ? "#fff #fff transparent transparent"
              : `${globalTheme?.main_color} ${globalTheme?.main_color} transparent transparent`,
        },
      }),
  };
