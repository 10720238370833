import { SxProps } from "@mui/system";

export const wrapperStyle = {
  display: "flex",
  justifyContent: "center",
  width: "100%",
};

export const hintsBox: SxProps = { wordBreak: "break-all", flex: 1 };

export const buttonCompWrapper = (rowLength: number): SxProps => ({
  flex: 3,
  marginBottom: 2,
  display: "grid",
  gridTemplateColumns: `repeat(${
    /* c8 ignore next 1 */ rowLength > 5 ? 5 : rowLength
  }, minmax(50px, 1fr))`,
  gap: 3,
  marginX: 2,
  marginRight: { xs: 4, md: 2 },
});
