import { SxProps } from '@mui/system'
import {
  ButtonCurrentValProps,
  ThemeObjectInterFace,
} from '../../../util/types'

const widthOfArrow = '15px'
const heightOfArrowPortrait = '100px'
const heightOfArrowLandscape = '25vh'

export const rankSorterContainer: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}

export const rankBoxContainer = (
  globalTheme: ThemeObjectInterFace,
  isLandScapeMode: boolean,
): SxProps => ({
  minHeight: isLandScapeMode ? '35vh' : null,
  padding: isLandScapeMode
    ? { md: '20px 16px', xs: '18px 14px' }
    : '8px 16px 16px',
  margin: '8px 16px',
  borderWidth: 2,
  boxShadow: 3,
  backgroundColor: 'white',
  borderRadius: 5,
  border: 2,
  borderColor: `${globalTheme?.main_color}`,
  flexBasis: isLandScapeMode ? { md: '35%', xs: '45%' } : null,
})

export const rankContent = (
  globalTheme: ThemeObjectInterFace,
  isErrorMessage?: string,
): SxProps => ({
  color: isErrorMessage
    ? `${globalTheme?.error_color}`
    : `${globalTheme?.main_color}`,
  marginBottom: 1,
  textAlign: 'center',
  fontSize: {
    xs: 15,
  },
  fontWeight: '500',
})

export const rankBoxChild = (isCardType: boolean): SxProps => ({
  width: '100%',
  display: isCardType ? 'inherit' : 'flex',
})

export const landScapeQuestionBox = (
  isCardType: boolean,
  globalTheme: ThemeObjectInterFace,
): SxProps => ({
  // height: isLandScapeMode isGreaterLength ? '5.7rem' : '',
  minHeight: isCardType ? '30vh' : null,
  padding: isCardType ? '16px 8px' : null,
  border: isCardType ? `3px solid ${globalTheme?.main_color}` : null,
  backgroundColor: isCardType ? 'white' : null,
  color: globalTheme?.main_color,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  wordBreak: 'break-word',
})

export const potraitQuestionBox = (
  globalTheme: ThemeObjectInterFace,
): SxProps => ({
  marginBottom: 1,
  border: 3,
  marginTop: 4,
  marginRight: {
    sm: 5,
    xs: 2,
  },
  borderColor: globalTheme?.main_color,
  padding: 1,
  paddingY: 4,
  height: '7rem',
  width: { md: '40%', xs: '100%' },
  overflow: 'scroll',
  alignItems: 'center',
  display: 'flex',
  color: globalTheme?.main_color,
  textAlign: 'center',
  fontWeight: 500,
  wordBreak: 'break-word',
  fontSize: 12,
})

export const rankBoxWrapper = (isCardType: boolean): SxProps => ({
  display: 'flex',
  width: '100%',
  justifyContent: isCardType ? 'space-around' : 'initial',
  flexDirection: { xs: 'column-reverse', md: 'row' },
})

export const arrowBox = (
  isLandScapeMode: boolean,
  isCardType: boolean,
): SxProps => ({
  width: {
    sm: isLandScapeMode || !isCardType ? '100%' : '50%',
  },
  marginLeft: {
    sm: isCardType ? 4 : 0,
    xs: isCardType ? 2 : 0,
  },
  marginTop: {
    sm: isCardType ? 2 : 0,
    xs: isCardType ? 1 : 0,
  },
})

export const arrowBoxWrapper = (isCardType: boolean): SxProps => ({
  display: 'flex',
  justifySelf: 'self-start',
  gap: isCardType ? 2 : 1,
})

export const arrowBoxWrapperFirstChild = (
  isCardType: boolean,
  values: ButtonCurrentValProps[],
): SxProps => ({
  display: 'flex',
  gap: '5px',
  ...(isCardType && { width: '5%' }),
  alignItems: 'center',
  justifyContent: values.length > 5 ? 'space-between' : 'center',
  flexDirection: 'column',
  marginRight: { md: 2, sm: 0.5, xs: 0.7 },
})

export const arrowLabel = (globalTheme: ThemeObjectInterFace): SxProps => ({
  color: globalTheme?.main_color,
  fontWeight: '500',
  fontSize: '18px',
})

export const arrowContainer: SxProps = {
  width: '20px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
}

export const arrowTop: SxProps = {
  width: 0,
  height: 0,
  borderLeft: `${widthOfArrow} solid transparent`,
  borderRight: `${widthOfArrow} solid transparent`,
}

export const arrowMiddle = (
  globalTheme: ThemeObjectInterFace,
  isLandScapeMode: boolean,
): SxProps => ({
  width: widthOfArrow,
  minHeight: isLandScapeMode ? heightOfArrowLandscape : heightOfArrowPortrait,
  backgroundColor: globalTheme.main_color,
})

export const valueContainer = (
  values: ButtonCurrentValProps[],
  isLandScapeMode: boolean,
): SxProps => ({
  width: '100%',
  minHeight: isLandScapeMode ? heightOfArrowLandscape : heightOfArrowPortrait,
  marginTop:
    values.length > 5 || values?.some((item) => typeof item.image == 'string')
      ? 4
      : '43px',
  display: 'flex',
  flexDirection: 'column',
  overflowY: 'scroll',
})

export const valueStyle = (globalTheme: ThemeObjectInterFace): SxProps => ({
  color: globalTheme?.main_color,
  fontSize: {
    sm: 20,
    xs: 16,
  },
  display: 'flex',
  alignItems: 'center',
})
export const buttonImageWraper = {
  marginX: 1,
  marginY: 0.5,
  fontSize: 16,
}
export const arrowRightSideText = (isDotesExist: boolean): SxProps => ({
  fontSize: { sm: 14, md: 16 },
  display: '-webkit-box',
  WebkitLineClamp: 1, // Number of lines to show
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  marginLeft: isDotesExist ? 1 : 0,
  marginRight: 0.5,
  width: '100%',
})

export const rightSideContainer = (): SxProps => ({
  flexBasis: '50%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  gap: '5px',
})
