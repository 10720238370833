import React, { forwardRef, useMemo } from "react";
import {
  ChildValueProps,
  GridSelectAction,
  SelectedtValue,
} from "../../../../../util/types";
import { gridButtonText, gridValueContent } from "./style";
import { useThemeContext } from "../../../../../globalContext/global-context";
import { Box } from "@mui/material";
import Hints from "../../../../ui/Hints";
import MultiInputsComponents from "../MultiNumericInput";

type GridSelectButtonProps = {
  actionRequest: GridSelectAction;
  handleSelect: (rowIndex: number, rattingBox: ChildValueProps) => void;
  selectedValue: SelectedtValue[];
  index: number;
  buttonContainerHeight: number[];
  childValue: string | ChildValueProps;
  colIndex: number;
  previous_response: string[] | undefined;
};

const GridButtons = forwardRef<HTMLDivElement, GridSelectButtonProps>(
  (
    {
      actionRequest,
      handleSelect,
      index,
      selectedValue,
      buttonContainerHeight,
      childValue,
      colIndex,
      previous_response,
    },
    questionRefs
  ) => {
    const { globalObject } = useThemeContext();
    const globalTheme = globalObject?.themeData?.theme;
    const isGrid_numeric = actionRequest?.type == "grid_numeric";
    const isSelected = useMemo(() => {
      if (!isGrid_numeric) {
        return selectedValue.some((item) => {
          return (
            item.key === index &&
            item.value === (childValue as ChildValueProps)?.value
          );
        });
      }
      return false;
    }, [selectedValue]);

    const multiNumericPreviousRes = () => {
      if (previous_response !== undefined) {
        return previous_response[colIndex].split(",")[index];
      }
    };

    const currentText = (childValue: string | ChildValueProps) => {
      /* c8 ignore next 3 */
      if (actionRequest?.type === "grid_numeric") {
        return childValue as string;
      } else if (
        actionRequest?.type === "grid_select" ||
        actionRequest?.type === "grid_multi_select"
      ) {
        return (childValue as ChildValueProps)?.text;
      } else {
        return (childValue as ChildValueProps)?.value;
      }
    };
    /* c8 ignore next 18*/
    const gridNumericOnchange = (e: { target: { value: string | number } }) => {
      if (isGrid_numeric) {
        const value = e.target.value;
        if (value == "" || 0) {
          handleSelect(index, {
            value: Number(value),
            colIndex: colIndex,
            emptyString: true,
          });
        } else if ((value as number) > 0) {
          handleSelect(index, {
            value: Number(value),
            colIndex: colIndex,
            emptyString: false,
          });
        }
      }
    };
    /* c8 ignore next */
    if (!questionRefs) return <Box />;
    return (
      <Box
        ref={questionRefs}
        onClick={() => {
          !(childValue as ChildValueProps)?.disabled &&
            !isGrid_numeric &&
            handleSelect(index, childValue as ChildValueProps);
        }}
        data-testId={`chip-${index}-${(childValue as ChildValueProps)?.value}`}
        sx={{
          ...gridValueContent(
            globalTheme,
            isSelected,
            (childValue as ChildValueProps)?.disabled,
            buttonContainerHeight[index],
            isGrid_numeric
          ),
        }}
      >
        {isGrid_numeric ? (
          <MultiInputsComponents
            onChange={gridNumericOnchange}
            colIndex={colIndex}
            rowIndex={index}
            defaultValue={multiNumericPreviousRes()}
          />
        ) : (
          <Hints
            sx={gridButtonText(isSelected)}
            text={currentText(childValue)}
          />
        )}
      </Box>
    );
  }
);

export default GridButtons;
