import { Box } from '@mui/system'
import React, { FC } from 'react'
import { Hints } from '../../../../ui'
import { textStyle } from './styles'
import { ThisOrThatColumnProps } from '../../../../../util/types'
import { LeftRightButton } from '../../components'

const ThisOrThatColumn: FC<ThisOrThatColumnProps> = ({
  RowsOption,
  handleClick,
  selectedBtns,
}) => {
  return (
    <Box data-testid={'grid_this_or_that'}>
      {RowsOption?.map((row, i) => {
        const idx = i + 1
        const isLastIndex = idx === RowsOption.length
        const leftOption = row?.[0]
        const rightOption = row?.[row.length - 1]

        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
            }}
            key={leftOption?.value}
          >
            <LeftRightButton
              source="this-or-that"
              column="left"
              idx={idx}
              isLastIndex={isLastIndex}
              name={leftOption?.text}
              onClick={handleClick}
              option={leftOption}
              thisOrThatSelectBtn={selectedBtns}
            />

            <Hints
              keyVal={`${leftOption?.value}-${idx}`}
              sx={textStyle}
              text="Or"
            />

            <LeftRightButton
              source="this-or-that"
              column="right"
              idx={idx}
              isLastIndex={isLastIndex}
              name={rightOption?.text}
              onClick={handleClick}
              option={rightOption}
              thisOrThatSelectBtn={selectedBtns}
            />
          </Box>
        )
      })}
    </Box>
  )
}

export default ThisOrThatColumn
