import { SxProps } from '@mui/system'

export const buttonStyle = (
  color: string,
  sourceBaseSelect: () => { selected: boolean | undefined; disabled: boolean },
  isLast?: boolean,
  isFirst?: boolean,
  source?: string,
): SxProps => ({
  ...(source !== 'this-or-that' && { maxWidth: '32%', minWidth: '20%' }),

  width: '100%',
  padding: '5px 20px',
  border: `2px solid ${color}`,
  borderRadius: 0,
  color,
  fontSize: '17px',
  fontWeight: 400,
  textTransform: 'none',
  ...(!isLast && {
    borderBottom: 0,
  }),
  ...(sourceBaseSelect().disabled && {
    borderColor: '#b3b3b3',
    backgroundColor: '#b3b3b3',
    color: '#fff',
    ...(!isFirst && {
      borderTopColor: '#fff',
    }),
    '&:hover': {
      backgroundColor: '#b3b3b3',
    },
  }),
  ...(sourceBaseSelect().selected && {
    borderColor: color,
    backgroundColor: color,
    color: '#fff',
    ...(!isFirst && {
      borderTopColor: '#fff',
    }),
    '&:hover': {
      backgroundColor: color,
    },
  }),
})

export const textStyle = (
  sourceBaseSelect: () => { selected: boolean | undefined; disabled: boolean },
): SxProps => ({
  fontWeight: 400,
  marginBottom: 0,
  fontSize: '17px',
  lineHeight: 1.75,
  ...(sourceBaseSelect()?.selected && {
    color: '#fff',
  }),
  ...(sourceBaseSelect()?.disabled && {
    color: '#fff',
  }),
})
