import { SxProps } from '@mui/system'
import { ThemeObjectInterFace } from '../../../util/types'

export const inputStyle = (
  globalTheme: ThemeObjectInterFace,
  // isFocused: boolean,
  isLandScapeMode: boolean,
): SxProps => ({
  height: '100%',
  width: '100%',
  borderRadius: '15px',
  border: 'none',
  backgroundColor: globalTheme?.secondary_color,
  '& textarea': {
    color: isLandScapeMode ? globalTheme?.main_color : null,
    fontSize: {
      md: '16px',
      xs: '14px',
    },
  },
  '& .MuiOutlinedInput-root': {
    padding: '5px 5px 5px 10px',
    '& fieldset': {
      borderColor: 'transparent', // Remove border color when not focused
    },
    '&:hover fieldset': {
      borderColor: 'transparent', // Remove border color on hover
    },
    '&.Mui-focused fieldset': {
      borderColor: 'transparent', // Remove border color when focused
    },
  },
})
