import { SxProps } from '@mui/system'
import { actionBtnWrapper } from '../../Rating/styles'

export const questionWrapper = (color: string): SxProps => ({
  width: '1200px',
  maxWidth: '90%',
  padding: '20px 3%',
  border: `2px solid ${color}`,
  borderRadius: '20px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#fff',

  '& > div': { flex: '1 1 auto' },
})

export const actionBtnThisOrThat: SxProps = {
  ...actionBtnWrapper,
  '@media (max-height: 300px)': {
    position: 'relative',
  },
}
