import { Box } from "@mui/system";
import React, { useEffect } from "react";
import { SendUndoProps } from "../../../util/types";
import { UNDO_MSG_CONTENT } from "../../../util/constants";
import { container } from "./styles";
import ActionButton from "../ActionButton";

const SendUndoButton: React.FC<SendUndoProps> = ({
  onClick,
  chatController,
  actionRequest,
  hideSendBtn,
  stopBack,
  disableSendBtn,
  isCommentModalOpen,
}) => {
  /* c8 ignore next */
  const undoFunction = () => {
    const res = { type: "undo", value: UNDO_MSG_CONTENT };
    chatController?.setActionResponse(actionRequest, res);
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (
      e.key === "Backspace" &&
      !isCommentModalOpen &&
      !stopBack &&
      actionRequest?.type !== "grid_numeric" &&
      actionRequest?.type !== "text_list"
    )
      undoFunction();
  };

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);

    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [chatController, isCommentModalOpen, stopBack]);
  const text = { text: "Send" };

  return (
    <Box sx={container}>
      {onClick && !hideSendBtn && (
        <Box>
          <ActionButton
            onClick={onClick}
            testId="landscape-send-button"
            currentVal={text}
            disabled={disableSendBtn}
          />
        </Box>
      )}
    </Box>
  );
};

export default SendUndoButton;
