export const diaVideoApicall = (
  sliderValue: number,
  offSetSeconds: number,
  post_data: string
) => {
  setTimeout(async () => {
    const url = new URL(window?.location?.href);

    const pathname = url.pathname;
    const id = pathname.split("/")[1];
    /* c8 ignore next 3*/
    const api_protocol =
      import.meta.env.VITE_MX8_SURVEY_API_PROTOCOL ?? "https";
    const api_url = `${api_protocol}://${
      import.meta.env.VITE_MX8_SURVEY_API_DOMAIN
    }/v1/store/${id}?value=${sliderValue}&offset_seconds=${offSetSeconds}`;
    try {
      await fetch(api_url, {
        method: "POST",
        body: post_data,
      });
      /* c8 ignore next 3*/
    } catch (error) {
      console.error("Error during API call:", error);
    }
  }, 1500);
};

export const visitorIdSentApiCall = async (visitorId: string) => {
  try {
    const api_protocol =
      /* c8 ignore next 1*/
      (import.meta.env.VITE_MX8_SURVEY_API_PROTOCOL as string) ?? "https";
    const url = new URL(window.location.href);

    const pathname = url.pathname;
    const id = pathname.split("/")[1];

    const endpoint: string =
      `${api_protocol}://${import.meta.env.VITE_MX8_SURVEY_API_DOMAIN}/v1/ui/` +
      `${id}?vid=${visitorId}`;
    // Send visitor ID to your custom endpoint
    await fetch(endpoint, {
      method: "GET",
    });
    /* c8 ignore next 3*/
  } catch (error) {
    console.error("Error sending visitor ID:", error);
  }
};
