import { TextField } from "@mui/material";
import React from "react";
import { inputPropsStyle, textFielsStyle } from "./style";
import { useThemeContext } from "../../../../../globalContext/global-context";
interface MultiInputsComponentsProps {
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  colIndex: number;
  rowIndex: number;
  defaultValue?: string;
}

const MultiInputsComponents = ({
  onChange,
  colIndex,
  rowIndex,
  defaultValue,
}: MultiInputsComponentsProps) => {
  const { globalObject } = useThemeContext();
  const globalTheme = globalObject?.themeData?.theme;
  return (
    <TextField
      data-testid={`Numeric-Grid-${rowIndex}${colIndex}`}
      onChange={onChange}
      defaultValue={defaultValue}
      fullWidth
      sx={textFielsStyle(globalTheme.main_color)}
      autoFocus={colIndex == 0 && rowIndex == 0}
      type="number"
      InputProps={{
        type: "number",
        sx: inputPropsStyle,
      }}
    />
  );
};

export default MultiInputsComponents;
