import { Box } from "@mui/system";
import { FC } from "react";
import { ThisOrThatButtonProps } from "../../../../../../util/types";
import { Hints } from "../../../../../ui";
import { LeftRightButton } from "../../../components";
import { buttonCompWrapper, hintsBox, wrapperStyle } from "./style";

const ThisOrThatButton: FC<ThisOrThatButtonProps> = ({
  RowsOption,
  handleClick,
  selectedBtns,
}) => {
  return (
    <>
      {RowsOption?.map((row, rowIdx) => {
        const idx = rowIdx + 1;
        const leftOption = row?.[0];
        const rightOption = row?.[row.length - 1];
        return (
          <Box sx={wrapperStyle} key={leftOption?.value}>
            {/* Left hint */}
            <Box sx={hintsBox}>
              <Hints sx={{ textAlign: "right" }} text={leftOption?.text} />
            </Box>
            {/* ButtonComponent Component */}
            <Box sx={buttonCompWrapper(row?.length)}>
              {row?.map((column, colIdx) => {
                return (
                  <LeftRightButton
                    key={`left-right-${column?.value}`}
                    source="this-or-that"
                    column={
                      /* c8 ignore next 4 */
                      colIdx == 0 ? "left" : "right"
                    }
                    idx={idx}
                    isLastIndex={true}
                    name={column?.value}
                    onClick={handleClick}
                    option={{ value: column?.value, text: column?.value }}
                    thisOrThatSelectBtn={selectedBtns}
                  />
                );
              })}
            </Box>
            {/* Right hint */}
            <Box sx={hintsBox}>
              <Hints sx={{ textAlign: "left" }} text={rightOption?.text} />
            </Box>
          </Box>
        );
      })}
    </>
  );
};

export default ThisOrThatButton;
