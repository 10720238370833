import { SxProps } from '@mui/system'
import { actionBtnWrapper } from '../../Rating/styles'

export const questionWrapper = (color: string): SxProps => ({
  width: '1200px',
  maxWidth: '90%',
  padding: '20px 3%',
  border: `2px solid ${color}`,
  borderRadius: '20px',
  margin: '0 auto',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  backgroundColor: '#fff',

  '& > div': { flex: '1 1 auto' },
})

export const textWrapper = (color: string): SxProps => ({
  flex: 1,
  color,
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  gap: 1,
})

export const textStyle = (isImageExist: boolean): SxProps => {
  return {
    flex: 1,
    fontSize: '17px',
    lineHeight: '1.75',
    textAlign: isImageExist ? 'end' : 'center',
    fontWeight: 600,
    marginBottom: 0,
  }
}

export const actionBtnMaxDiff: SxProps = {
  ...actionBtnWrapper,
  '@media (max-height: 300px)': {
    position: 'relative',
  },
}
