import { Box, SxProps } from "@mui/system";
import { FC, useState } from "react";
import { ButtonCurrentValProps } from "../../../util/types";
import {
  imageTextWrapper,
  loaderContainer,
  onlyImageStyle,
  textStyle,
  withTextImageStyle,
} from "./styles";
import { CircularProgress } from "@mui/material";
import { useThemeContext } from "../../../globalContext/global-context";
type CommonImageCompProps = {
  currentVal: ButtonCurrentValProps;
  type?: string;
  isLandScapeMode?: boolean;
  sx?: SxProps;
};

const CommonImageComp: FC<CommonImageCompProps> = ({
  currentVal,
  type,
  isLandScapeMode,
  sx,
}) => {
  const [imageLoad, setImageLoad] = useState(true);
  const { image, text } = currentVal;
  const { globalObject } = useThemeContext();
  const globalTheme = globalObject?.themeData?.theme;
  /* c8 ignore start  */
  if (image && text) {
    return (
      <Box sx={imageTextWrapper}>
        {/* button text */}
        <Box sx={textStyle(isLandScapeMode)}>{text}</Box>
        {/* button image */}
        <Box
          component="img"
          className="buttons-image-reponsive"
          data-testid="common-image&text"
          src={image}
          sx={withTextImageStyle(isLandScapeMode, type)}
          onLoad={() => {
            /* c8 ignore next  4 */
            setImageLoad(false);
          }}
        />
        {imageLoad && (
          <Box className="buttons-image-reponsive" sx={loaderContainer}>
            <CircularProgress
              size="1.5rem"
              sx={{ color: globalTheme?.main_color }}
            />
          </Box>
        )}
      </Box>
    );
  } else if (image) {
    return (
      <>
        <Box
          component="img"
          className={"buttons-image-reponsive"}
          data-testid="common-image"
          src={image}
          sx={onlyImageStyle(sx, isLandScapeMode, type, imageLoad)}
          onLoad={() => {
            /* c8 ignore next  3 */
            setImageLoad(false);
          }}
        />

        {imageLoad && (
          <Box className="buttons-image-reponsive" sx={loaderContainer}>
            <CircularProgress
              size="1.5rem"
              sx={{ color: globalTheme?.main_color }}
            />
          </Box>
        )}
      </>
    );
  } else {
    return <Box data-testid="common-text">{text}</Box>;
  }
  /* c8 ignore end  */
};

export default CommonImageComp;
