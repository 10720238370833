import { Slider, Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import { forwardRef } from "react";
import { VideoDialResponeProps } from "../../../util/types";
import { videoIframe } from "../VideoResponse/styles";
import {
  dialContainer,
  dialSliderStyle,
  dialWraper,
  hintsStyle,
  iframeStyle,
  mainBoax,
  mobileViewDialStyleTextStyle,
  mobileViewWrapper,
  sliderWrapper,
  subContainerForSliderBottom,
  videoWrapper,
} from "./style";
import { Hints } from "../../ui";
import {
  handleKeyDown,
  handleChange,
} from "../VideoResponse/videoResponseFunc";
import { useThemeContext } from "../../../globalContext/global-context";

const VideoDialResponse = forwardRef<
  HTMLIFrameElement | HTMLDivElement,
  VideoDialResponeProps
>(
  (
    {
      message,
      isLandScapeMode,
      dialOpen,
      sliderValue,
      mobileView,
      setSliderValue,
      offsetSeconds,
    },
    devRef
  ) => {
    const sliderMarks = message?.options?.map((item) => {
      return { value: item.value, label: item.text };
    });
    const { globalObject } = useThemeContext();
    const globalTheme = globalObject?.themeData?.theme;
    const dialStyleRight = message?.style == "vdoCipher_dial_right";
    const lastIndexOfSliderLabel =
      (message?.options && message?.options?.length - 1) ?? 0;
    /* c8 ignore next 2 */
    const hintText = message?.playing_message ?? "";
    const dialStyleBottomRender = (index: number, text: string) => {
      if (index == 0) {
        return { text: text, textAlign: "start" };
      } else if (index == lastIndexOfSliderLabel) {
        return { text: text, textAlign: "end" };
      } else {
        return { textAlign: "center" };
      }
    };
    const mobileViewDialStyleCheck = () => {
      return mobileView || dialStyleRight;
    };
    return (
      <Box ref={devRef}>
        {
          /* c8 ignore next start */
          isLandScapeMode && message?.options ? (
            <Box sx={{ ...dialContainer(mobileView, dialOpen) }}>
              <Box sx={{ ...dialWraper(dialOpen) }}>
                {!mobileView && !dialStyleRight && dialOpen && (
                  <Hints
                    text={hintText}
                    sx={hintsStyle(mobileView, globalTheme?.main_color)}
                  />
                )}
                <Box
                  sx={{
                    ...mainBoax(mobileView, dialOpen, dialStyleRight),
                  }}
                >
                  <Box
                    sx={{
                      ...videoWrapper(mobileView, dialOpen, dialStyleRight),
                    }}
                  >
                    <iframe
                      src={message?.video}
                      title="Video Player"
                      allow="encrypted-media"
                      style={iframeStyle(
                        dialOpen,
                        isLandScapeMode,
                        dialStyleRight,
                        mobileView
                      )}
                      allowFullScreen={false}
                    ></iframe>
                  </Box>
                  {dialOpen && (
                    <Box sx={mobileViewWrapper(mobileView, dialStyleRight)}>
                      {mobileViewDialStyleCheck() && dialOpen && (
                        <Hints
                          text={hintText}
                          sx={hintsStyle(mobileView, globalTheme?.main_color)}
                        />
                      )}
                      <Box
                        sx={{
                          ...(mobileViewDialStyleCheck() &&
                            subContainerForSliderBottom()),
                          marginTop: 6,
                        }}
                      >
                        <Box sx={sliderWrapper(mobileView, dialStyleRight)}>
                          <Slider
                            data-testid={"slidr"}
                            size="small"
                            valueLabelDisplay="on"
                            orientation={
                              mobileViewDialStyleCheck()
                                ? "vertical"
                                : "horizontal"
                            }
                            aria-label="slider-with-focus"
                            marks={sliderMarks}
                            sx={dialSliderStyle(
                              mobileView,
                              lastIndexOfSliderLabel,
                              dialStyleRight,
                              globalTheme?.main_color
                            )}
                            value={sliderValue}
                            onKeyDown={(
                              event: React.KeyboardEvent<HTMLDivElement>
                            ) => {
                              handleKeyDown(
                                event,
                                setSliderValue,
                                sliderValue,
                                offsetSeconds,
                                message.post_data ?? ""
                              );
                            }}
                            onChange={(event, value) => {
                              /* c8 ignore next 8 */
                              handleChange(
                                value as number,
                                setSliderValue,
                                message?.post_data ?? "",
                                offsetSeconds
                              );
                            }}
                          />
                          {!mobileView && !dialStyleRight && (
                            <Box
                              sx={{
                                height: "100%",
                                width: "100%",
                                display: "flex",
                                flexDirection:
                                  /* c8 ignore next 4 */ mobileViewDialStyleCheck()
                                    ? "column"
                                    : "row",
                                justifyContent: "space-between",
                                gap: 4,
                                marginTop: -2.5,
                              }}
                            >
                              {sliderMarks?.map((item, index) => {
                                return (
                                  <Box
                                    key={index + 1}
                                    sx={{
                                      color: globalTheme?.main_color,
                                      width:
                                        /* c8 ignore next 3 */
                                        lastIndexOfSliderLabel > 5
                                          ? "15%"
                                          : "20%",

                                      textAlign: dialStyleBottomRender(
                                        index,
                                        item?.label
                                      )?.textAlign,
                                    }}
                                  >
                                    {
                                      dialStyleBottomRender(index, item?.label)
                                        ?.text
                                    }
                                  </Box>
                                );
                              })}
                            </Box>
                          )}
                        </Box>
                        {mobileViewDialStyleCheck() && (
                          <Box
                            sx={{
                              height:
                                /* c8 ignore next 3 */ mobileViewDialStyleCheck()
                                  ? "100%"
                                  : "80%",
                              width: dialStyleRight ? "65%" : "30%",
                              display: "flex",
                              flexDirection:
                                /* c8 ignore next 3 */ mobileViewDialStyleCheck()
                                  ? "column-reverse"
                                  : "row",
                              justifyContent: "space-between",
                            }}
                          >
                            {sliderMarks?.map((item, index) => {
                              return (
                                <Tooltip
                                  key={index + 1}
                                  title={item.label}
                                  arrow
                                >
                                  <Box
                                    sx={{
                                      ...mobileViewDialStyleTextStyle(
                                        dialStyleRight,
                                        globalTheme?.main_color
                                      ),
                                    }}
                                  >
                                    {item.label}
                                  </Box>
                                </Tooltip>
                              );
                            })}
                          </Box>
                        )}
                      </Box>
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            <iframe
              data-testid="video-container"
              title="VideoPlayer"
              allow="encrypted-media"
              src={message?.video}
              style={videoIframe(!!isLandScapeMode)}
              allowFullScreen={true}
            ></iframe>
          )
          /* c8 ignore next end */
        }
      </Box>
    );
  }
);

export default VideoDialResponse;
