const CARD_RANK_LABEL_1 = 'Best'
const CARD_RANK_LABEL_2 = 'Worst'

const UNKNOWN_MSG = {
  type: 'text',
  content: 'Unknown message.',
  self: false,
}

const UNDO_MSG_CONTENT = 'Undoing last response...'

const AUTO_RESPONSE_MSG_CONTENT = 'autoresponse'

const DONT_KNOW_MSG = "I don't know"

export const ThemeObjectLandscape = {
  main_color: '#49AE8A',
  secondary_color: '#fff',
  error_color: '#F19C99',
  icon_image: 'themes/shanna/shanna.jpeg',
  line_1: 'Talking with',
  line_2: 'Shanna Researcher',
  background_image: 'themes/shanna/background.jpeg',
  redirect_message: "We'll send you back in {seconds} seconds",
  disqualification_message:
    "We're sorry but you don't qualify for this survey today",
  orientation: 'landscape',
  hints: {
    waiting: 'Shanna Researcher is Typing...',
    multichoice: 'Select your option...',
    multiselect: 'Select your options...',
    others: 'Enter your message...',
  },
}

export const ThemeObjectPotrait = {
  ...ThemeObjectLandscape,
  orientation: 'potrait',
}

export {
  AUTO_RESPONSE_MSG_CONTENT,
  CARD_RANK_LABEL_1,
  CARD_RANK_LABEL_2,
  DONT_KNOW_MSG,
  UNDO_MSG_CONTENT,
  UNKNOWN_MSG,
}
