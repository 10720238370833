import React, { FC } from 'react'
import { ThisOrThatButtonSliderProps } from '../../../../../util/types'
import ThisOrThatButton from './ThisOrThatButton'
import ThisOrThatSlider from './ThisOrThatSlider'
import { Box } from '@mui/system'

const ThisOrThatButtonSlider: FC<ThisOrThatButtonSliderProps> = ({
  actionRequest,
  handleClick,
  selectedBtns,
  updateMarkValue,
  setUpdateMarkValue,
  sliderValue,
  setSliderValue,
  setIsAllSliderValueChange,
}) => {
  return (
    <Box data-testid={'grid_this_or_that_rating'}>
      {actionRequest?.style == 'slider' && (
        <ThisOrThatSlider
          actionRequest={actionRequest}
          RowsOption={actionRequest?.row_options}
          updateMarkValue={updateMarkValue}
          setUpdateMarkValue={setUpdateMarkValue}
          sliderValue={sliderValue}
          setSliderValue={setSliderValue}
          setIsAllSliderValueChange={setIsAllSliderValueChange}
        />
      )}
      {actionRequest?.style?.toLowerCase() == 'button' && (
        <ThisOrThatButton
          actionRequest={actionRequest}
          RowsOption={actionRequest?.row_options}
          handleClick={handleClick}
          selectedBtns={selectedBtns}
        />
      )}
    </Box>
  )
}

export default ThisOrThatButtonSlider
