import { Box } from "@mui/material";
import React, { FC } from "react";
import { textMark } from "../../../Rating/styles";
import {
  titleMainContainer,
  valueContainer,
} from "../../Numeric-Select-MultiSelct-Slider/style";
import { valueContent } from "../../../Rating/components/SliderRating/styles";
import { GridSelectAction, MarkValueType } from "../../../../../util/types";
import { useThemeContext } from "../../../../../globalContext/global-context";
import { Hints } from "../../../../ui";

type RatingLabelsProps = {
  actionRequest?: GridSelectAction;
  isSliderType: boolean;
  isLandScapeMode: boolean;
  updateMarkValue: MarkValueType[];
};

const RatingLabels: FC<RatingLabelsProps> = ({
  actionRequest,
  isSliderType,
  isLandScapeMode,
  updateMarkValue,
}) => {
  const { globalObject } = useThemeContext();
  const globalTheme = globalObject?.themeData?.theme;
  return (
    <Box sx={titleMainContainer}>
      <Box sx={{ ...valueContainer, marginBottom: 2 }}>
        <Hints
          text={actionRequest?.options[0]?.text}
          sx={{
            ...textMark(globalTheme, isLandScapeMode),
            textAlign: "left",
            marginBottom: 0,
          }}
        />
        <Hints
          text={
            actionRequest?.options[
              Math?.floor((actionRequest?.options?.length - 1) / 2)
            ]?.text
          }
          sx={{ ...textMark(globalTheme, isLandScapeMode), marginBottom: 0 }}
        />
        <Hints
          text={
            actionRequest?.options[actionRequest?.options?.length - 1]?.text
          }
          sx={{
            ...textMark(globalTheme, isLandScapeMode),
            textAlign: "right",
            marginBottom: 0,
          }}
        />
      </Box>
      {isSliderType && (
        <Box sx={valueContainer}>
          {updateMarkValue?.map((item, index) => {
            return (
              <Box
                key={item.value}
                data-testid={`chip-${index + 1}`}
                sx={valueContent(globalTheme)}
              >
                {item.resValue}
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
};

export default RatingLabels;
