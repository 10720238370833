import { SxProps } from "@mui/system";

export const countdownContainer: SxProps = {
  position: "relative",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
};

export const circularProgress: SxProps = {
  color: "primary",
  position: "absolute",
  left: 0,
  transform: "scaleX(-1) rotate(-90deg)",
};
